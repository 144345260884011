<template>
  <div class="confirm-modal" :class="{ _m: isMobile }">
    <h3 v-if="question.length" class="confirm-modal__question">
      {{ question }}
    </h3>
    <p v-if="description.length" class="confirm-modal__description">
      {{ description }}
    </p>
    <div v-if="showAccept || showCancel" class="confirm-modal__buttons">
      <button-control
        v-if="showAccept"
        class="confirm-modal__button"
        type="secondary"
        @click.prevent="handleAcceptClick"
      >
        {{ textAccept }}
      </button-control>
      <button-control
        v-if="showCancel"
        class="confirm-modal__button"
        type="secondary"
        @click.prevent="handlerCancelClick"
      >
        {{ textCancel }}
      </button-control>
    </div>
  </div>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';
import { ButtonControl } from '@book24/ui-components';

import { checkMobile } from '@/utils/deviceCheck';

export default defineComponent({
  name: 'ModalConfirm',
  components: { ButtonControl },
  props: {
    textAccept: { type: String, default: 'Да' },
    showAccept: { type: Boolean, default: true },
    textCancel: { type: String, default: 'Нет' },
    showCancel: { type: Boolean, default: true },
    question: { type: String, default: '' },
    description: { type: String, default: '' },
  },
  setup(props, { emit }) {
    const isMobile = checkMobile();

    const handleAcceptClick = () => {
      emit('accept');
    };

    const handlerCancelClick = () => {
      emit('cancel');
    };

    return {
      isMobile,
      handleAcceptClick,
      handlerCancelClick,
    };
  },
});
</script>

<style lang="less">
.confirm-modal {
  max-width: 384px;
  text-align: center;
  background-color: @white;
  border-radius: @main-size;
  margin: auto;
  padding: @main-size * 12;
  position: relative;

  &._m {
    padding: @main-size * 12 @size-x4 @size-x4;
  }

  &__question {
    .h-reset();
    .h3-desktop();

    margin-bottom: @size-x3;
  }

  &__description {
    .p();

    margin: 0 0 @size-x3;
  }

  &__buttons {
    margin-top: @size-x6;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__button {
    width: 132px;
  }
}
</style>
